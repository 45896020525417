<template>
  <div class="ask_center flex">
    <div class="ask_left">
      <div class="avatar">
        <img :src="avatar.LOGO" alt="" class="err_avatar">
        <div class="name">
          {{ avatar.userName }}
        </div>
      </div>
    </div>
    <div class="ask_right">
      <ul class="tab flex">
        <li v-for="(item,index) in tab" :class="{'active':active == index}" :key="index" @click="tabs(index)">
          {{ item }}
        </li>
      </ul>
      <div class="list list1" v-if="active == 0">
        <ul>
          <li class="box relative" v-for="(n,i) in list" :key="i">
            <h2 @click="goRouter('/asklib/askDetail',n.id)">{{ n.askTitle }}</h2>
            <span class="time">{{ n.createDate.slice(0,10) }}</span>
<!--            <div class="avatar cursorP" @click="goRouter('/asklib/askCenter',n.memberId,n.isAnonymity)">-->
<!--              <img :src="n.isAnonymity == 0?n.askPhoto:null" alt="" class="err_avatar">-->
<!--              <span>{{ n.isAnonymity == 0 ? n.askName : '匿名用户' }}</span>-->
<!--            </div>-->
            <div class="text flex marT10"  @click="goRouter('/asklib/askDetail',n.id)">
              <viewer :images="JSON.parse(n.pic1)" class="inline_block viewer" v-if="JSON.parse(n.pic1)">
                <img :src="item" alt="" :width="JSON.parse(n.pic1).length > 1?100:200"
                     v-for="(item,index) in JSON.parse(n.pic1)" :key="index">
              </viewer>
              <span>{{ n.content }}</span></div>
            <p>
              <span><img src="~/static/ask/read.png" alt="">{{ n.viewNumber }}阅读</span>
              <span><img src="~/static/ask/comments.png" alt="">{{ n.answerNumber }}回答</span>
              <span><img src="~/static/ask/focus.png" alt="">{{ n.collectNumber }}关注</span>
            </p>
          </li>
        </ul>
        <status :type="status.type" v-if="status.isHide == 1"></status>
      </div>
      <div class="list list2" v-if="active == 1">
        <ul>
          <li class="box flex" v-for="(n,i) in list1" :key="i">
          <div class="info">
            <div class="top flex">
              <h3>
                <router-link :to="'/asklib/askDetail?id='+n.askId" :title="n.askTitle" target="_blank">{{ n.askTitle }}</router-link>
              </h3>
              <div class="time">
                {{ n.createDate }}
              </div>
            </div>

            <div class="avatar">
              <img :src="n.photo" alt="" class="err_avatar">
              <span>{{ n.name }}</span>
            </div>
            <div class="text" v-html="n.content"></div>
            <div class="features flex relative">
              <div :class="n.zan?'praise':''" @click="answerDianzan(n.id,n.zan)">
                <p>
                  <img src="~/static/ask/zan.png" alt="">
                  <img src="~/static/ask/zan_green.png" alt="" class="green">
                </p>
                {{ n.usefulNumber }}赞
              </div>
            </div>
          </div>

        </li>
        </ul>
        <status :type="status.type" v-if="status.isHide == 2"></status>
      </div>
      <div class="list list3" v-if="active == 2">
        <ul>
          <li class="box" v-for="(n,i) in list2" :key="i">
          <div class="avatar">
            <img :src="n.photo" alt="" class="err_avatar">
            <span>{{ n.name }}</span>
            <span class="comment">评论了回答</span>
          </div>
          <div class="text" v-html="n.answerTitle">
          </div>
          <div class="const">
            {{ n.content }}
          </div>
          <div class="features flex relative">
            <div :class="n.zan?'praise':''" @click="commentDianzan(n.answerId,n.id,n.zan)">
              <p>
                <img src="~/static/ask/zan.png" alt="">
                <img src="~/static/ask/zan_green.png" alt="" class="green">
              </p>
              {{ n.usefulNumber }}赞
            </div>
          </div>
        </li>
        </ul>
        <status :type="status.type" v-if="status.isHide == 3"></status>
      </div>
      <div class="list list3 list4" v-if="active == 3">
        <ul>
          <li class="box" v-for="(n,i) in list3" :key="i">
            <div class="text">
              {{ n.askName }}
            </div>
            <div class="const" v-html="n.aswearName">
            </div>
            <div class="features flex relative">
              <div>
                <!--<p>-->
                <!--<img src="~/static/ask/zan.png" alt="">-->
                <!--</p>-->
                <!--12赞-->
              </div>
              <div class="time">
                {{ n.createDate }}
              </div>
            </div>
          </li>
        </ul>
        <status :type="status.type" v-if="status.isHide == 4"></status>
      </div>
      <div class="list list1" v-if="active == 4">
        <ul>
          <li class="box relative" v-for="(n,i) in list4" :key="i">
            <h2 @click="goRouter('/asklib/askDetail',n.id)">{{ n.askTitle }}</h2>
            <span class="time">{{ n.createDate }}</span>
            <div class="avatar cursorP" @click="goRouter('/asklib/askCenter',n.memberId,n.isAnonymity)">
              <img :src="n.isAnonymity == 0?n.askPhoto:null" alt="" class="err_avatar">
              <span>{{ n.isAnonymity == 0 ? n.askName : '匿名用户' }}</span>
            </div>
            <div class="text flex">
              <viewer :images="JSON.parse(n.pic1)" class="inline_block viewer" v-if="JSON.parse(n.pic1)">
                <img :src="item" alt="" :width="JSON.parse(n.pic1).length > 1?100:200"
                     v-for="(item,index) in JSON.parse(n.pic1)" :key="index">
              </viewer>
              <span  @click="goRouter('/asklib/askDetail',n.id)">{{ n.content }}</span></div>
            <p>
              <span><img src="~/static/ask/read.png" alt="">{{ n.viewNumber }}阅读</span>
              <span><img src="~/static/ask/comments.png" alt="">{{ n.answerNumber }}回答</span>
              <span><img src="~/static/ask/focus.png" alt="">{{ n.collectNumber || 0}}关注</span>
            </p>
          </li>
        </ul>
        <status :type="status.type" v-if="status.isHide == 5"></status>
      </div>
      <div class="pages">
        <el-pagination
            v-if="page.total"
            background
            class="work_pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            :page-size="page.pageSize"
            layout="prev, pager, next, total, jumper"
            :total="page.total">
          <!--v-show="page.total > 10"-->
        </el-pagination>
      </div>
    </div>
    <login-prompt :goShow="isHide" @close="handleClose" title="登录" text="登录之后才可以点赞"></login-prompt>
  </div>
</template>

<script>
import loginPrompt from '@/components/public/loginPrompt'
import status from "@/components/public/status";

export default {
  name: "askCenter",
  components: {
    loginPrompt,
    status
  },
  data() {
    return {
      active: 0,
      tab: [
        '提问', '回答', '评论', '点赞', '关注的问题'
      ],
      avatar: [],
      list: [],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        memberId: '',
        total: 0,
      },
      image: [],
      isHide: false,
      status:{
        type:'record',
        isHide:false
      },
      isLogin:'',
    }
  },
  methods: {
    avatarInfo() {
      this.$axios.get('/api/app-jycy-ask/getLogoName', {memberId: this.page.memberId}).then(res => {
        if (res.data.success) {
          this.avatar = res.data.data;
          this.isLogin = this.$store.state.userType || JSON.parse(localStorage.getItem('userType'));
        }
      })
    },
    info(i) {
      let url = '/api/app-jycy-ask/AuthorCenterGetAsk';
      if (i == 0) {
        url = '/api/app-jycy-ask/AuthorCenterGetAsk';
      } else if (i == 1) {
        url = '/api/app-jycy-ask/AuthorCenterGetAswear';
      } else if (i == 2) {
        url = '/api/app-jycy-ask/AuthorCenterGetComment';
      } else if (i == 3) {
        url = '/api/app-jycy-ask/AuthorCenterGetDianZan';
      } else {
        url = '/api/app-jycy-ask/AuthorCenterGetGuan';
      }
      this.$axios.get(url, this.page).then(res => {
        if (res.data.success) {
          this.page.total = res.data.data.total;
          switch (i) {
            case 0:
              this.list = res.data.data.records;
              if (this.page.total == 0){
                this.status.isHide = 1;
              }
              break
            case 1:
              this.list1 = res.data.data.records;
              if (this.page.total == 0){
                this.status.isHide = 2;
              }
              break
            case 2:
              this.list2 = res.data.data.records;
              if (this.page.total == 0){
                this.status.isHide = 3;
              }
              break
            case 3:
              this.list3 = res.data.data.records;
              if (this.page.total == 0){
                this.status.isHide = 4;
              }
              break
            case 4:
              this.list4 = res.data.data.records;
              if (this.page.total == 0){
                this.status.isHide = 5;
              }
              break
          }
        }else{
          switch (i) {
            case 0:
                this.status.isHide = 1;
              break
            case 1:
                this.status.isHide = 2;
              break
            case 2:
                this.status.isHide = 3;
              break
            case 3:
                this.status.isHide = 4;
              break
            case 4:
                this.status.isHide = 5;
              break
          }
        }
      }).catch(err =>{
        this.status.isHide = i+1;
        this.status.isHide = 'wifi';
      })
    },
    tabs(i) {
      this.active = i;
      this.info(i)
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.info(this.active);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    //点赞回答
    answerDianzan(id, zan) {
      if (this.isLogin) {
        this.$api.answerDianzanApi({answerId: id}).then(res => {
          if (zan) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
          }
          this.info(1);
        })
      } else {
        this.isHide = true;
      }
    },
    //点赞评论
    commentDianzan(id, ids, zan) {
      if (this.isLogin){
        this.$api.commentDianzanApi({id: ids}).then(res => {
          if (zan) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
          }
          this.info(2);
        })
      }else{
        this.isHide = true;
      }

    },
    //路由
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id
        }
      })
      window.open(href, '_blank')
      this.$api.readApi({id: id}).then(res => {
      });
    },
    handleClose() {
      this.isHide = false;
    },
  },
  created() {
    if (this.$route.query.id) {
      this.page.memberId = this.$route.query.id;
      this.avatarInfo();
      this.info(0, '/app-jycy-ask/AuthorCenterGetAsk');
      //判断是否移动端
      this.$mobile(
          "https://lzmz.cdpee.org.cn/#/pages/community/question/author?id=" +
          this.$route.query.id
      );
    }
  },
  mounted() {
    this.$emit('goRouter', '/asklib/')
  },
}
</script>

<style scoped lang="less">
@import "~assets/css/ask.less";

</style>
